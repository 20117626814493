:root {
    /* Default dark theme values to prevent white flash during loading. These will be overridden by the theme selected by the user. */
    --app-bg: #121212;
    --text-primary: #ffffff;
    --primary-button: #1976d2;
    --surface-bg-transparent: rgba(42, 42, 42, 0.5);

    /* Status Colors */
    --status-not-started: var(--theme_status-not-started, #6c757d);
    --status-not-started-rgb: 108, 117, 125;
    
    --status-in-progress: var(--theme_status-in-progress, #0d6efd);
    --status-in-progress-rgb: 13, 110, 253;
    
    --status-in-review: var(--theme_status-in-review, #ffc107);
    --status-in-review-rgb: 255, 193, 7;
    
    --status-ready-for-golive: var(--theme_status-ready-for-golive, #fd7e14);
    --status-ready-for-golive-rgb: 253, 126, 20;
    
    --status-completed: var(--theme_status-completed, #198754);
    --status-completed-rgb: 25, 135, 84;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Dark Theme */
body {
    background-color: var(--app-bg);
    color: var(--text-primary);
    --webkit-tap-highlight-color: var(--app-bg);
}

/* Google Chewy Font */
.chewy-regular {
    font-family: "Chewy", system-ui;
    font-weight: 400;
    font-style: normal;
}

/* Scrollbar styles */
@layer utilities {
    *, select {
        scrollbar-width: auto;
        scrollbar-color: var(--scrollbar-thumb) var(--input-bg);
    }

    /* Webkit scrollbar styles for select dropdowns */
    select::-webkit-scrollbar {
        width: auto;
    }

    select::-webkit-scrollbar-track {
        background: var(--input-bg);
    }

    select::-webkit-scrollbar-thumb {
        background-color: var(--scrollbar-thumb);
        border-radius: 8px;
        border: 2px solid var(--input-bg);
    }

    /* Hover state for better interactivity */
    select::-webkit-scrollbar-thumb:hover {
        background-color: var(--scrollbar-thumb-hover);
    }

    .flash-button {
        position: relative;
        overflow: hidden;
        isolation: isolate;
        -webkit-tap-highlight-color: transparent;
    }

    .flash-button::before,
    .flash-button::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);
        opacity: 0;
        border-radius: 8px;
        transform: translate(-50%, -50%);
    }

    .flash-button:active::before {
        animation: flash 0.2s ease-out;
    }

    .flash-button:active::after {
        animation: ripple 0.6s ease-out;
    }

    @keyframes flash {
        0% { opacity: 0; }
        50% { opacity: 0.25; }
        100% { opacity: 0; }
    }

    @keyframes ripple {
        0% {
            transform: translate(-50%, -50%) scale(0);
            opacity: 0.4;
        }
        100% {
            transform: translate(-50%, -50%) scale(2.5);
            opacity: 0;
        }
    }

    .base-button {
        @apply bg-[var(--button-bg)] border border-[var(--surface-border)] hover:bg-[var(--button-hover-bg)] text-white;
    }

    /* Theme Background Colors */
    .bg-surface {
        background-color: var(--surface-bg);
    }
    .bg-surface-lighter {
        background-color: var(--surface-bg-lighter);
    }
    .bg-surface-darker {
        background-color: var(--surface-bg-darker);
    }
    .bg-surface-transparent {
        background-color: var(--surface-bg-transparent);
    }
    .bg-primary {
        background-color: var(--primary-button);
    }
    .bg-primary-hover {
        background-color: var(--primary-button-hover);
    }
    .hover-bg-surface:hover {
        background-color: var(--surface-bg);
    }
    .hover-bg-surface-lighter:hover {
        background-color: var(--surface-bg-lighter);
    }
    .hover-bg-primary:hover {
        background-color: var(--primary-button-hover);
    }

    /* Theme Border Colors */
    .border-surface {
        border-color: var(--surface-border);
    }
    .border-surface-lighter {
        border-color: var(--surface-bg-lighter);
    }
    .border-light {
        border-color: var(--border-light);
    }
    .border-dark {
        border-color: var(--border-dark);
    }
    .hover-border-primary:hover {
        border-color: var(--primary-button);
    }
    .focus-border-primary:focus {
        border-color: var(--primary-button);
    }
    .focus-border-white:focus {
        border-color: var(--text-primary);
    }

    /* Focus Ring */
    .focus-ring {
        @apply focus:ring-2 focus:ring-[var(--focus-ring)];
    }
}

/* Checkmark animation styles */
.checkmark-icon {
    opacity: 0;
    transform: scale(0);
    transition: all 0.2s ease-in-out;
}

input[type="checkbox"]:checked + label .checkmark-icon {
    opacity: 1;
    transform: scale(1);
}

    /* Loading Ellipses styles */
    .animate-ellipsis span {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: currentColor;
        display: inline-block;
    }

    .animate-ellipsis span:nth-child(1) {
        animation: 
            bounce 1s ease-in-out infinite,
            fade 2s ease-in-out infinite;
    }

    .animate-ellipsis span:nth-child(2) {
        animation: 
            bounce 1s ease-in-out 0.15s infinite,
            fade 2s ease-in-out 0.15s infinite;
    }

    .animate-ellipsis span:nth-child(3) {
        animation: 
            bounce 1s ease-in-out 0.3s infinite,
            fade 2s ease-in-out 0.3s infinite;
    }

    @keyframes bounce {
        0%, 100% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-10px);
        }
    }

    @keyframes fade {
        0%, 100% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
}

/* Sidebar styles */
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: color-mix(in srgb, var(--app-bg) 80%, transparent);
    backdrop-filter: blur(8px);
    transition: all 300ms;
    border-right: 1px solid var(--surface-border);
    display: flex;
    flex-direction: column;
    z-index: 50;
}

.sidebar.collapsed {
    width: 4rem;
}

.sidebar.expanded {
    width: 16rem;
}

.status-indicator {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-left: auto;
    flex-shrink: 0;
}

.sidebar-item-content {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
}

/* Sidebar Filter Styles */
@layer components {
    .sidebar select {
        appearance: none;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23888888' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: right 8px center;
        background-size: 16px;
        padding-right: 32px;
    }

    .sidebar select option {
        background-color: var(--input-bg);
        color: var(--text-primary);
        padding: 8px;
    }

    .sidebar select:focus {
        outline: none;
        border: 1px solid var(--primary-button);
    }

    .sidebar input[type="text"] {
        border: 1px solid var(--surface-border);
    }

    .sidebar input[type="text"]:focus {
        outline: none;
        border: 1px solid var(--input-border-hover);
    }
}

/* Icon Select Styles */
.material-symbols-outlined {
    vertical-align: middle;
}

/* Slider styles */
.slider-container {
    position: relative;
    width: 300px;
    height: 40px;
    background: var(--slider-bg);
    border-radius: 8px;
    overflow: hidden;
}

.slider-track {
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 4px;
    right: 4px;
    transition: transform 300ms ease-out;
    background: var(--slider-track);
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    border-radius: 6px;
    width: calc(50% - 4px);
}

.slider-buttons {
    position: relative;
    z-index: 10;
    display: flex;
    height: 100%;
}

.slider-button {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-size: 14px;
    font-weight: 500;
    transition: color 200ms;
    color: var(--slider-text);
    cursor: pointer;
    border: none;
    background: transparent;
}

.slider-button:hover:not(.active) {
    color: var(--slider-text-hover);
}

.slider-button.active {
    color: var(--slider-text-active);
}

.modal-content {
    border-right: 1px solid var(--surface-border);
}

.react-datepicker {
    background-color: var(--input-bg);
    color: var(--text-primary);
}

.react-datepicker__input-container input {
    border: 1px solid var(--primary-button);
}

.react-datepicker__input-container input:focus {
    border: 1px solid var(--input-border-hover);
}

.react-datepicker__header {
    background: var(--surface-bg);
}

.sidebar-item-content > span:nth-child(2) {
    word-break: break-all;
    min-width: 0;
}
