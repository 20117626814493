.status-selector {
  position: relative;
  padding: 10px;
  margin: 10px 0;
  min-height: 100px;
}

.timeline-base {
  position: absolute;
  left: 68px;
  right: 68px;
  top: 35px;
  height: 2px;
  background: rgba(255, 255, 255, 0.1);
  z-index: 0;
}

.timeline-progress {
  position: absolute;
  left: 68px;
  top: 35px;
  height: 2px;
  transition: all 0.3s ease;
  z-index: 1;
  width: var(--progress-width, 0);
  max-width: calc(100% - 136px);
  background: var(--status-color);
}

.status-buttons {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  padding: 0;
}

.status-button {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  transition: all 0.2s ease;
  color: var(--text-muted);
  flex: 1;
  max-width: 120px;
}

.status-button.main-branch {
  position: relative;
  flex: 1;
  padding-top: 19px;
}

.status-button.review-branch {
  position: absolute;
  left: calc(68px + ((100% - 136px) / 4));
  top: 47px;
  transform: translateX(-50%);
  flex: none;
  width: 120px;
}

.review-branch::before {
  content: '';
  position: absolute;
  top: -12px;
  left: 50%;
  width: 2px;
  height: 12px;
  background: rgba(255, 255, 255, 0.1);
  z-index: 0;
  transition: background-color 0.2s ease;
}

.review-branch.active::before {
  background: var(--status-color);
  opacity: 1;
}

.status-node {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--status-color);
  transition: all 0.2s ease;
  border: 2px solid transparent;
  z-index: 2;
}

.status-button:hover .status-node {
  transform: scale(1.2);
}

.status-button.active .status-node {
  transform: scale(1.3);
  box-shadow: 0 0 0 4px rgba(var(--status-color-rgb), 0.2);
}

.status-label {
  font-size: 0.85rem;
  font-weight: 500;
  opacity: 0.8;
  transition: all 0.2s ease;
  text-align: center;
  white-space: nowrap;
}

.status-button:hover .status-label {
  opacity: 1;
}

.status-button.active .status-label {
  opacity: 1;
  font-weight: 600;
}

/* Remove the after pseudo-element since we're using timeline-progress now */
.status-button::after {
  display: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .status-button.review-branch {
    left: calc(68px + ((100% - 136px) / 4));
    width: 100px;
  }
}

@media (max-width: 600px) {
  .status-selector {
    padding: 5px;
    min-height: 140px;
  }

  .status-buttons {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 23px;
    gap: 12px;
    position: relative;
  }

  .status-button {
    flex-direction: row;
    align-items: center;
    gap: 12px;
    max-width: none;
    width: 100%;
    justify-content: flex-start;
    padding: 4px;
    padding-left: 0;
    pointer-events: none;
  }

  .status-button.main-branch {
    padding-top: 4px;
    &:nth-child(1) { order: 1; }
    &:nth-child(2) { order: 3; }
    &:nth-child(3) { order: 4; }
    &:nth-child(4) { order: 5; }
  }

  .timeline-base {
    left: 33px;
    top: 25px;
    width: 2px;
    height: calc(100% - 40px);
    right: auto;
  }

  .timeline-progress {
    left: 33px;
    width: 2px !important;
    top: 25px;
    transition: all 0.3s ease;
    height: var(--progress-height, 0%);
  }

  .status-button.review-branch {
    position: relative;
    left: calc(33px + 15%);
    top: 0;
    transform: none;
    width: calc(85% - 33px);
    padding-left: 0;
    order: 2;
  }

  .review-branch::before {
    content: '';
    position: absolute;
    top: 50%;
    left: calc(-15% - 33px);
    width: calc(15% + 33px);
    height: 2px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 0;
    transition: background-color 0.2s ease;
  }

  .review-branch.active::before {
    background: var(--status-color);
  }

  .status-label {
    font-size: 0.9rem;
    text-align: left;
  }

  .status-node {
    flex-shrink: 0;
    pointer-events: auto;
    cursor: pointer;
  }

  .status-label {
    pointer-events: auto;
    cursor: pointer;
    margin-left: 4px;
  }
}

/* Update tablet/medium screen breakpoint */
@media (min-width: 601px) and (max-width: 768px) {
  .timeline-base {
    left: 60px;
    right: 60px;
  }

  .timeline-progress {
    left: 60px;
    max-width: calc(100% - 120px);
  }

  .status-button.review-branch {
    left: calc(60px + ((100% - 120px) / 4));
    width: 100px;
  }
}

/* Add intermediate breakpoint */
@media (min-width: 769px) and (max-width: 1024px) {
  .timeline-base {
    left: 64px;
    right: 64px;
  }

  .timeline-progress {
    left: 64px;
    max-width: calc(100% - 128px);
  }

  .status-button.review-branch {
    left: calc(64px + ((100% - 128px) / 4));
    width: 110px;
  }
}

/* Large screens - revert to original calculations */
@media (min-width: 1025px) {
  .timeline-base {
    left: 68px;
    right: 68px;
  }

  .timeline-progress {
    left: 68px;
    max-width: calc(100% - 136px);
  }

  .status-button.review-branch {
    left: calc(68px + ((100% - 136px) / 4));
    width: 120px;
  }
}

/* Add these utility classes */
.status-not-started {
  --status-color: var(--status-not-started);
  --status-color-rgb: var(--status-not-started-rgb);
}

.status-in-progress {
  --status-color: var(--status-in-progress);
  --status-color-rgb: var(--status-in-progress-rgb);
}

.status-in-review {
  --status-color: var(--status-in-review);
  --status-color-rgb: var(--status-in-review-rgb);
}

.status-completed {
  --status-color: var(--status-completed);
  --status-color-rgb: var(--status-completed-rgb);
}

.status-ready-for-golive {
  --status-color: var(--status-ready-for-golive);
  --status-color-rgb: var(--status-ready-for-golive-rgb);
}

/* Update status button styles */
.status-button:hover,
.status-button.active {
  color: var(--text-primary);
}

/* Remove the inline color styles and use theme variables */
.status-button.status-not-started .status-node {
  background: var(--status-not-started);
}

.status-button.status-in-progress .status-node {
  background: var(--status-in-progress);
}

.status-button.status-in-review .status-node {
  background: var(--status-in-review);
}

.status-button.status-completed .status-node {
  background: var(--status-completed);
}

.status-button.status-ready-for-golive .status-node {
  background: var(--status-ready-for-golive);
}

/* Update the active state shadow */
.status-button.active.status-not-started .status-node {
  box-shadow: 0 0 0 4px rgba(var(--status-not-started-rgb), 0.2);
}

.status-button.active.status-in-progress .status-node {
  box-shadow: 0 0 0 4px rgba(var(--status-in-progress-rgb), 0.2);
}

.status-button.active.status-in-review .status-node {
  box-shadow: 0 0 0 4px rgba(var(--status-in-review-rgb), 0.2);
}

.status-button.active.status-completed .status-node {
  box-shadow: 0 0 0 4px rgba(var(--status-completed-rgb), 0.2);
}

.status-button.active.status-ready-for-golive .status-node {
  box-shadow: 0 0 0 4px rgba(var(--status-ready-for-golive-rgb), 0.2);
}
  